var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",attrs:{"id":"editModal"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{},[_c('b-card-code',{attrs:{"title":"Update Settings"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeMultiple)+" ")]},proxy:true}])},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('Translations',{on:{"lang":function($event){return _vm.handleLanguage($event)}}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"titel"}},[_vm._v("Title")]),_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","name":"title","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.title),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "title", $$v)},expression:"updatedSettings.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone")]),_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","name":"phone","state":errors.length > 0 ? false:null,"placeholder":"","type":"text"},model:{value:(_vm.updatedSettings.phone),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "phone", $$v)},expression:"updatedSettings.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"fax"}},[_vm._v("Fax")]),_c('validation-provider',{attrs:{"name":"Fax","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fax","name":"fax","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.fax),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "fax", $$v)},expression:"updatedSettings.fax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.email),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "email", $$v)},expression:"updatedSettings.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"address"}},[_vm._v("Address")]),_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","name":"address","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.address),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "address", $$v)},expression:"updatedSettings.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-2",attrs:{"md":"12"}},[_c('h4',[_vm._v("Social Media")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"facebook"}},[_vm._v("Facebook")]),_c('validation-provider',{attrs:{"name":"Facebook","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","name":"facebook","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.facebook),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "facebook", $$v)},expression:"updatedSettings.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"twitter"}},[_vm._v("Twitter")]),_c('validation-provider',{attrs:{"name":"twitter","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","name":"twitter","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.twitter),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "twitter", $$v)},expression:"updatedSettings.twitter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"youtube"}},[_vm._v("Youtube")]),_c('validation-provider',{attrs:{"name":"youtube","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"youtube","name":"youtube","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.youtube),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "youtube", $$v)},expression:"updatedSettings.youtube"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"instagram"}},[_vm._v("Instagram")]),_c('validation-provider',{attrs:{"name":"Instagram","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"instagram","name":"instagram","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.instagram),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "instagram", $$v)},expression:"updatedSettings.instagram"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"linkedin"}},[_vm._v("Linkedin")]),_c('validation-provider',{attrs:{"name":"Linkedin","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linkedin","name":"linkedin","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.linkedin),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "linkedin", $$v)},expression:"updatedSettings.linkedin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"whatsapp"}},[_vm._v("Whatsapp")]),_c('validation-provider',{attrs:{"name":"Whatsapp","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"whatsapp","name":"whatsapp","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.whatsapp),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "whatsapp", $$v)},expression:"updatedSettings.whatsapp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-2",attrs:{"md":"12"}},[_c('h4',[_vm._v("Website")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"description"}},[_vm._v("Description")]),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('quill-editor',{attrs:{"id":"description","name":"content","options":_vm.snowOption},model:{value:(_vm.updatedSettings.website_text),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "website_text", $$v)},expression:"updatedSettings.website_text"}})],_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-2",attrs:{"md":"12"}},[_c('h4',[_vm._v("Pages Links")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"about"}},[_vm._v("About Us «English»")]),_c('validation-provider',{attrs:{"name":"About","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"about","name":"about","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.about),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "about", $$v)},expression:"updatedSettings.about"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"about_ar"}},[_vm._v("About Us «Arabic»")]),_c('validation-provider',{attrs:{"name":"About Arabic","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"about_ar","name":"about_ar","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.about_ar),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "about_ar", $$v)},expression:"updatedSettings.about_ar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"terms"}},[_vm._v("Terms & Conditions «English»")]),_c('validation-provider',{attrs:{"name":"Terms","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"terms","name":"terms","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.terms),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "terms", $$v)},expression:"updatedSettings.terms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"terms_ar"}},[_vm._v("Terms & Conditions «Arabic»")]),_c('validation-provider',{attrs:{"name":"Terms Arabic","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"terms_ar","name":"terms_ar","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.terms_ar),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "terms_ar", $$v)},expression:"updatedSettings.terms_ar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"privacy"}},[_vm._v("Privacy Policy «English»")]),_c('validation-provider',{attrs:{"name":"Privacy","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"privacy","name":"privacy","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.privacy),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "privacy", $$v)},expression:"updatedSettings.privacy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"privacy_ar"}},[_vm._v("Privacy Policy «Arabic»")]),_c('validation-provider',{attrs:{"name":"Privacy Arabic","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"privacy_ar","name":"privacy_ar","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.updatedSettings.privacy_ar),callback:function ($$v) {_vm.$set(_vm.updatedSettings, "privacy_ar", $$v)},expression:"updatedSettings.privacy_ar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])])],1)],1)],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" × ")])])}]

export { render, staticRenderFns }