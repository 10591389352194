import AbstractService from '@/services/abstractService'

class SettingsService extends AbstractService {
  endpoint = 'settings'
  website() {
    return this.http.get('website-settings')
  }

}

const settingsService = new SettingsService()

export default settingsService
