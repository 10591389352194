<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <!-- The Modal -->
  <div
    id="editModal"
    class="modal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
            title="Update Settings"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <Translations @lang="handleLanguage($event)" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label for="titel">Title</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Title"
                        rules="required"
                      >
                        <b-form-input
                          id="title"
                          v-model="updatedSettings.title"
                          name="title"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="phone">Phone</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                        rules="required"
                      >
                        <b-form-input
                          id="phone"
                          v-model="updatedSettings.phone"
                          name="phone"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="fax">Fax</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Fax"
                        rules=""
                      >
                        <b-form-input
                          id="fax"
                          v-model="updatedSettings.fax"
                          name="fax"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="email">Email</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules=""
                      >
                        <b-form-input
                          id="email"
                          v-model="updatedSettings.email"
                          name="email"
                          type="email"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--                  <b-col md="12">-->
                  <!--                    <b-form-group>-->
                  <!--                      <label for="contact_us_email">Inquiries Email</label>-->
                  <!--                      <validation-provider-->
                  <!--                          #default="{ errors }"-->
                  <!--                          name="Inquiries Email"-->
                  <!--                          rules=""-->
                  <!--                      >-->
                  <!--                        <b-form-input-->
                  <!--                            id="contact_us_email"-->
                  <!--                            v-model="updatedSettings.contact_us_email"-->
                  <!--                            name="contact_us_email"-->
                  <!--                            type="contact_us_email"-->
                  <!--                            :state="errors.length > 0 ? false:null"-->
                  <!--                            placeholder=""-->
                  <!--                        />-->
                  <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                  <!--                      </validation-provider>-->
                  <!--                    </b-form-group>-->
                  <!--                  </b-col>-->
                  <b-col md="12">
                    <b-form-group>
                      <label for="address">Address</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Address"
                        rules="required"
                      >
                        <b-form-input
                          id="address"
                          v-model="updatedSettings.address"
                          name="address"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col
                    class="mb-2 mt-2"
                    md="12"
                  >
                    <h4>Social Media</h4>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="facebook">Facebook</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Facebook"
                        rules=""
                      >
                        <b-form-input
                          id="facebook"
                          v-model="updatedSettings.facebook"
                          name="facebook"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="twitter">Twitter</label>
                      <validation-provider
                        #default="{ errors }"
                        name="twitter"
                        rules=""
                      >
                        <b-form-input
                          id="twitter"
                          v-model="updatedSettings.twitter"
                          name="twitter"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="youtube">Youtube</label>
                      <validation-provider
                        #default="{ errors }"
                        name="youtube"
                        rules=""
                      >
                        <b-form-input
                          id="youtube"
                          v-model="updatedSettings.youtube"
                          name="youtube"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="instagram">Instagram</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Instagram"
                        rules=""
                      >
                        <b-form-input
                          id="instagram"
                          v-model="updatedSettings.instagram"
                          name="instagram"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="linkedin">Linkedin</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Linkedin"
                        rules=""
                      >
                        <b-form-input
                          id="linkedin"
                          v-model="updatedSettings.linkedin"
                          name="linkedin"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="whatsapp">Whatsapp</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Whatsapp"
                        rules=""
                      >
                        <b-form-input
                          id="whatsapp"
                          v-model="updatedSettings.whatsapp"
                          name="whatsapp"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col
                      class="mb-2 mt-2"
                      md="12"
                  >
                    <h4>Website</h4>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="description">Description</label>
                      <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                      >
                        <template>
                          <quill-editor
                              id="description"
                              v-model="updatedSettings.website_text"
                              name="content"
                              :options="snowOption"
                          />
                        </template>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                </b-row>

                <hr>
                <b-row>
                  <b-col
                    class="mb-2 mt-2"
                    md="12"
                  >
                    <h4>Pages Links</h4>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="about">About Us «English»</label>
                      <validation-provider
                        #default="{ errors }"
                        name="About"
                        rules=""
                      >
                        <b-form-input
                          id="about"
                          v-model="updatedSettings.about"
                          name="about"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="about_ar">About Us «Arabic»</label>
                      <validation-provider
                        #default="{ errors }"
                        name="About Arabic"
                        rules=""
                      >
                        <b-form-input
                          id="about_ar"
                          v-model="updatedSettings.about_ar"
                          name="about_ar"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group>
                      <label for="terms">Terms & Conditions «English»</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Terms"
                        rules=""
                      >
                        <b-form-input
                          id="terms"
                          v-model="updatedSettings.terms"
                          name="terms"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="terms_ar">Terms & Conditions «Arabic»</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Terms Arabic"
                        rules=""
                      >
                        <b-form-input
                          id="terms_ar"
                          v-model="updatedSettings.terms_ar"
                          name="terms_ar"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group>
                      <label for="privacy">Privacy Policy «English»</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Privacy"
                          rules=""
                      >
                        <b-form-input
                            id="privacy"
                            v-model="updatedSettings.privacy"
                            name="privacy"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="privacy_ar">Privacy Policy «Arabic»</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Privacy Arabic"
                          rules=""
                      >
                        <b-form-input
                            id="privacy_ar"
                            v-model="updatedSettings.privacy_ar"
                            name="privacy_ar"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                </b-row>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>
            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Translations from '../../../shared/translations/Translations.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea

} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import { codeMultiple, codeBasic } from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import settingsService from '../../../services/settingsService'
import pagesService from '@/services/pagesService'
import { quillEditor } from 'vue-quill-editor'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    Translations,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
    quillEditor,

  },
  props: ['oldSettings'],
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      object: this,
      newFile: false,
      required,
      codeMultiple,
      codeBasic,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init: function() {
          this.on("maxfilesexceeded", function(file) {
            this.newFile = true;
            this.removeAllFiles();
            this.addFile(file);
          });
        },
      },

      updatedSettings: {
        title: '',
        phone: '',
        fax: '',
        address: '',
        facebook: '',
        twitter: '',
        youtube: '',
        instagram: '',
        linkedin: '',
        whatsapp: '',
        email: '',
        contactEmail: '',
        about: '',
        about_ar: '',
        terms: '',
        terms_ar: '',
        privacy: '',
        privacy_ar: '',
        website_text: ''
      },
    }
  },
  mounted() {
    const object = this
    this.$watch(() => this.oldSettings, oldSettings => {
      this.oldSettings = oldSettings
      console.log(oldSettings)
      this.updatedSettings= {
        title: oldSettings.title,
        phone:oldSettings.mobile,
        fax: oldSettings.fax,
        address:oldSettings.address,
        facebook: oldSettings.facebook,
        twitter: oldSettings.twitter,
        youtube: oldSettings.youtube,
        instagram: oldSettings.instagram,
        linkedin: oldSettings.linkedin,
        whatsapp: oldSettings.whatsapp,
        email: oldSettings.email,
        contact_us_email: oldSettings.contactEmail,
        about: oldSettings.about,
        about_ar: oldSettings.about_ar,
        terms: oldSettings.terms,
        terms_ar: oldSettings.terms_ar,
        privacy: oldSettings.privacy,
        privacy_ar: oldSettings.privacy_ar,
        website_text: oldSettings.website_text,

      }
    })
  },
  methods: {
    handleLanguage(lang) {
      settingsService.show(this.oldSettings.id, { lang })
          .then(response => {
            this.updatedSettings = {
              id: response.data.data.id,
              title: response.data.data.title,
              phone:response.data.data.mobile,
              fax: response.data.data.fax,
              address:response.data.data.address,
              facebook: response.data.data.facebook,
              twitter: response.data.data.twitter,
              youtube: response.data.data.youtube,
              instagram: response.data.data.instagram,
              linkedin: response.data.data.linkedin,
              whatsapp: response.data.data.whatsapp,
              email: response.data.data.email,
              contact_us_email: response.data.data.contactEmail,
              about:  response.data.data.about,
              about_ar:  response.data.data.about_ar,
              terms:  response.data.data.terms,
              terms_ar:  response.data.data.terms_ar,
              privacy:  response.data.data.privacy,
              privacy_ar:  response.data.data.privacy_ar,
            }
        })
    },
    formatNames(files){
      if (files.length === 1) {
        return files[0].name
      }
      return `${files.length} files selected`
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              settingsService.put(this.oldSettings.id, this.updatedSettings).then(response => {
                if (response && response.status === 200) {
                  this.$emit('refresh', 'true')
                  $('#editModal').modal('hide')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Basic Information Is Updated Successfully!',
                    },
                  })
                }
                this.loading = false
              })
            }
          }).catch(function (error) {

        $('#editModal').modal('hide')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}
</style>
